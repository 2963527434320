<template>
  <div v-if="(!organizationStore.isPhoneVerified && !hide) && !isBannerShown" class="phone-strip">
    {{ t('common.phoneStripText', {amount: '$20'}) }}
    <BaseButton class="text-nowrap w-auto" middle-size @click="onShowPhoneModal">{{t('common.phoneStripButton', {amount: '$20'})}}</BaseButton>
    <div class="strip-close" @click="doNotShowAgain">
      <IconClose/>
    </div>
  </div>
</template>

<script setup>

import {useI18n} from "vue-i18n";
import BaseButton from "Common/components/controls/BaseButton";
import IconClose from "Common/assets/icons/close.svg?inline";
import {useBadgeNotify} from "Common/stores/badgeNotify";
import {useOrganizationStore} from "Common/stores/organizationStore";
import {useUserStore} from "Common/stores/userStore";
import {computed} from "vue";
import {ROUTES} from "@/utility/routeUtils";
import router from "@/routes";

const {t, locale} = useI18n({useScope: 'global'});

const organizationStore = useOrganizationStore();
const userStore = useUserStore();
const badgeNotify = useBadgeNotify();

const hide = computed(() => {
  return router.currentRoute.value?.matched?.some(i => [
    ROUTES.AdminOrganizationDetails
  ].includes(i.name));
})
const bannerName = 'phoneStrip';
const isBannerShown = computed(() => !!userStore?.user?.shownBanners?.includes(bannerName));

function onShowPhoneModal(){
  organizationStore.showPhoneVerificationModal = true;
}

function doNotShowAgain() {
  const { externalId, shownBanners: userShownBanners = [] } = userStore?.user || {};
  const shownBanners = [...new Set([...userShownBanners, bannerName])];

  const data = { externalId, shownBanners };

  userStore.updateUserProfile(data).catch(err => {
    const { validationData } = err || {};
    const errorType = ['missing', 'invalid'].find(type => validationData?.[`${type}Params`]?.length);

    const field = validationData?.[`${errorType}Params`]?.[0]?.field;
    const text = field ? t(`user_profile.update_error_${errorType}_${field}`) : t('user_profile.update_error');

    badgeNotify.show({ text, error: true });
  });
}
</script>

<style lang="scss" scoped>
.phone-strip{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 6px 24px;
  background-color: #45557D;
  color: white;
  text-align: center;

  .strip-close {
    margin-left: 10px;

    svg {
      stroke: white;
    }
  }

  .strip-close:hover{
    cursor: pointer;
  }
}

.mobile-device{ //1024 - ...

  @media (max-width: 1023px){ //678

  }

  @media (max-width: 767px){ //375
    .phone-strip{
      flex-direction: column !important;
      gap: 6px;
    }
  }
}

</style>
